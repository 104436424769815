import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo_dark from '@/assets/images/rasa/logo.png';
import logo_light from '@/assets/images/rasa/logo.png';
import { ROUTES } from '@/constants/routes';

export default function Navbar(props) {
  const { navClass, navJustify } = props;

  window.addEventListener('scroll', windowScroll);

  const [isMenu, setisMenu] = useState(false);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById('navigation')) {
      const anchorArray = Array.from(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('navigation').getElementsByTagName('a'),
      );
      anchorArray.forEach((element) => {
        element.addEventListener('click', (elem) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const target = elem.target.getAttribute('href');
          if (target !== '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (elem.target.nextElementSibling) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        });
      });
    }
  };

  function windowScroll() {
    const navbar = document.getElementById('topnav');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      if (navbar !== null) {
        navbar?.classList.add('nav-sticky');
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove('nav-sticky');
      }
    }

    const mybutton = document.getElementById('back-to-top');
    if (mybutton != null) {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        mybutton.classList.add('flex');
        mybutton.classList.remove('hidden');
      } else {
        mybutton.classList.add('hidden');
        mybutton.classList.remove('flex');
      }
    }
  }

  const activateMenu = () => {
    let parentOfParent;
    const menuItems = document.getElementsByClassName('sub-menu-item');
    if (menuItems) {
      let matchingMenuItem = null;
      for (let idx = 0; idx < menuItems.length; idx++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (menuItems[idx].href === window.location.href) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          matchingMenuItem = menuItems[idx];
        }
      }
      if (matchingMenuItem) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        matchingMenuItem.classList.add('active');

        const immediateParent = getClosest(matchingMenuItem, 'li');

        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        let parent = getClosest(immediateParent, '.child-menu-item');
        if (parent) {
          parent.classList.add('active');
        }

        parent = getClosest(parent || immediateParent, '.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          const parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }

          parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Element.prototype.matchesSelector ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Element.prototype.mozMatchesSelector ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Element.prototype.msMatchesSelector ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line prefer-const
          let matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-empty
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  useEffect(() => {
    activateMenu();
    window.scrollTo(0, 0);
  }, []);

  const navbarHtml = (
    <>
      <nav
        id="topnav"
        className={`defaultscroll ${
          navClass === 'nav-light'
            ? ''
            : navClass === 'nav-sticky'
            ? 'bg-white dark:bg-slate-900'
            : ''
        }`}
      >
        <div className="container relative">
          {navClass === 'nav-light' ? (
            <Link className="logo" to="/index">
              <span className="inline-block dark:hidden">
                <img src={logo_dark} className="l-dark" style={{ width: 100 }} alt="" />
                <img
                  src={logo_light}
                  className="l-light"
                  style={{ width: 100 }}
                  height="10"
                  alt=""
                />
              </span>
              <img
                src={logo_light}
                style={{ width: 100 }}
                className="hidden dark:inline-block"
                alt=""
              />
            </Link>
          ) : (
            <Link className="logo" to="/index">
              <img
                src={logo_dark}
                style={{ width: 100 }}
                className="inline-block dark:hidden"
                alt=""
              />
              <img
                src={logo_light}
                style={{ width: 100 }}
                className="hidden dark:inline-block"
                alt=""
              />
            </Link>
          )}

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className={`navbar-toggle ${isMenu ? 'open' : ''}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
            <ul className={`navigation-menu ${navClass} ${navJustify}`}>
              <li>
                <Link to={ROUTES.HOME} className="sub-menu-item">
                  Inicio
                </Link>
              </li>
              <li>
                <Link to={ROUTES.SERVICES} className="sub-menu-item">
                  Servicios
                </Link>
              </li>
              <li>
                <Link to={ROUTES.ABOUT_US} className="sub-menu-item">
                  Acerca de nosotros
                </Link>
              </li>
              <li>
                <Link to={ROUTES.CONTACT_US} className="sub-menu-item">
                  Contactanos
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );

  return <div>{navbarHtml}</div>;
}
