import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Search,
  Bookmark,
  Codesandbox,
  Send,
  Star,
} from 'feather-icons-react/build/IconComponents';
import TinySlider from 'tiny-slider-react';
import CountUp from 'react-countup';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';
import CookieModal from '@/component/cookieModal';
import ManagementTeam from '@/component/managementTeam';

import { BUSINESS } from '@/pages/index/data/business';
import { SERVICES } from '@/pages/index/data/services';
import { ROUTES } from '@/constants/routes';

import imageP from '@/assets/images/consulting/1.jpg';
import image1 from '@/assets/images/consulting/2.jpg';
import image2 from '@/assets/images/consulting/3.jpg';
import ab03 from '@/assets/images/about/ab03.jpg';
import ab02 from '@/assets/images/about/ab02.jpg';
import ab01 from '@/assets/images/about/ab01.jpg';
import pro1 from '@/assets/images/rasa/property.jpg';
import pro2 from '@/assets/images/rasa/liability.jpg';
import pro3 from '@/assets/images/rasa/construction.jpg';
import pro4 from '@/assets/images/rasa/marine.jpg';
import pro5 from '@/assets/images/rasa/catastopher.jpg';
import pro6 from '@/assets/images/rasa/goverment.jpg';

const settings = {
  container: '.tiny-single-item',
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  speed: 400,
  gutter: 16,
};

export default function IndexConsulting() {
  return (
    <>
      <Navbar navClass="nav-light" />

      {/* Section title home */}
      <section className="relative md:py-60 py-36 bg-[url('../../assets/images/consulting/bg.jpg')] bg-right bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 items-center mt-10 text-center">
            <span className="text-white/80 font-semibold mb-4 text-lg">
              Servicio especializado en dictaminación e indemnización de siniestros
            </span>
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">
              REYNOSO & ASSOCIATES <br />
              <TypeAnimation
                sequence={[' SPECIALIZED ADJUSTMENTS', 2000]}
                wrapper="span"
                speed={50}
                className="typewrite"
                repeat={Infinity}
              />
            </h4>
            <p className="text-white/60 text-lg">
              En Rasa nos comprometemos a darte la transparencia que necesitas
            </p>
          </div>
        </div>
      </section>

      {/* Section business with have been working
      <section className="py-6 border-t border-b border-gray-100 dark:border-gray-700">
        <div className="container relative">
          <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
            {BUSINESS.map((item, index) => {
              return (
                <div key={index} className="mx-auto py-4">
                  <img src={item.logo} className="h-6" alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      */}

      {/* Services resume */}
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Ajustadores especializados
            </h3>

            <p className="text-dark-500 text-2xl max-w-xl mx-auto">
              Somos una empresa conformada por expertos en diferentes campos del sector asegurador.
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <img src={imageP} className="" alt="" />
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>

              <div className="mt-6">
                <Link
                  to="#"
                  className="text-xl font-semibold hover:text-sky-600 transition-all duration-500 ease-in-out"
                >
                  Experiencia que te respalda
                </Link>

                <p className="text-slate-500 mt-4">
                  Contamos con amplia experiencia en diferentes áreas del campo productivo y
                  conocemos extensamente los procesos de distribución de mercancías. (Cadena de
                  distribución Logística)
                </p>
              </div>
            </div>

            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <img src={image1} className="" alt="" />
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>

              <div className="mt-6">
                <Link
                  to="#"
                  className="text-xl font-semibold hover:text-sky-600 transition-all duration-500 ease-in-out"
                >
                  Visión innovadora
                </Link>

                <p className="text-slate-500 mt-4">
                  Contamos con una visión innovadora enfocada en la mejora de los procesos de
                  “Ajuste de daños” ya que procedemos de lado del cliente y conocemos las
                  necesidades no satisfechas.
                </p>
              </div>
            </div>

            <div className="group relative">
              <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                <img src={image2} className="" alt="" />
                <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
              </div>

              <div className="mt-6">
                <Link
                  to="#"
                  className="text-xl font-semibold hover:text-sky-600 transition-all duration-500 ease-in-out"
                >
                  Transparencia
                </Link>

                <p className="text-slate-500 mt-4">
                  Tenemos presente que, lo más importante para la Aseguradora es tener transparencia
                  y claridad en la determinación de pérdidas y del lado del cliente, sabemos la
                  importancia de responder con eficiencia y eficacia a la dictaminación e
                  indemnización de los siniestros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Experience resume */}
      <section className="relative bg-gray-50 dark:bg-slate-800 md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab03} className="shadow rounded-md" alt="" />
                    <img src={ab02} className="shadow rounded-md" alt="" />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab01} className="shadow rounded-md" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <div className="flex mb-4">
                  <span className="text-sky-800 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={17}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Años de <br /> Experiencia en el sector asegurador
                  </span>
                </div>

                <div className="flex mb-4">
                  <span className="text-sky-800 text-2xl font-bold mb-0">
                    <CountUp
                      className="counter-value text-6xl font-bold"
                      start={1}
                      end={25}
                    ></CountUp>
                    +
                  </span>
                  <span className="self-end font-semibold ms-2">
                    Años de <br /> Experiencia en el área logística
                  </span>
                </div>
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Con RASA tendrás al mejor grupo de expertos.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
            <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Nuestros Servicios
              </h3>
              <p className="text-slate-500 max-w-xl">Con RASA tendrás al mejor grupo de expertos</p>
            </div>

            <div className="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
              <Link
                to={ROUTES.SERVICES}
                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-sky-600 hover:text-sky-600 after:bg-sky-600 duration-500"
              >
                Saber más <i className="uil uil-arrow-right align-middle"></i>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-2">
            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                    <img
                      src={pro1}
                      style={{ width: '100%' }}
                      className="rounded-t-md shadow"
                      alt=""
                    />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      Daños a propiedad
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Property)</span>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                    <img
                      src={pro2}
                      style={{ width: '100%' }}
                      className="rounded-t-md shadow"
                      alt=""
                    />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-emerald-600 transition-all duration-500 ease-in-out"
                    >
                      Seguro de responsabilidad
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Liability)</span>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-red-600/5 dark:bg-red-600/30">
                    <img src={pro3} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-red-600 transition-all duration-500 ease-in-out"
                    >
                      Ingeniería y construcción
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Construction & Engineering)</span>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-sky-600/5 dark:bg-sky-600/30">
                    <img src={pro4} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-sky-600 transition-all duration-500 ease-in-out"
                    >
                      Seguro de transportes
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Marine & Inland Merine)</span>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-amber-600/5 dark:bg-amber-600/30">
                    <img src={pro5} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-amber-600 transition-all duration-500 ease-in-out"
                    >
                      Respuesta a catástrofes
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Catastrophe Response)</span>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                    <img src={pro6} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      Cuentas gubernamentales
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Goverment Accounts)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container relative mt-16 lg:pt-24 pt-16">
          <p className="text-slate-500 mt-4 text-sm">
            REYNOSO & ASSOCIATES SPECIALIZED ADJUSTMENTS, mejor conocido como RASA S.C, con
            domicilio en calle Cerrada Abasolo # 7, colonia La Pedrera, ciudad Puebla, Pue, C.P.
            72040, hace de su conocimiento qué, es el responsable del uso y protección de sus datos
            personales que se recaben o generen con motivo de la relación comercial que tengamos
            celebrada. Utilizaremos su información personal para las finalidades principales que son
            necesarias para el servicio solicitado, y que nos permiten y facilitan brindarle una
            mejor atención, quedando convenido que usted acepta la transferencia de datos que
            pudiera realizarse a las entidades descritas en el Aviso de Privacidad que usted puede
            encontrar en la pagina de internet www.rasasc.com . Por el contrario, si no acepta que
            sus datos sean utilizados para estos fines sirvace confirmarlo por escrito. Para más
            información sobre los términos y condiciones en que serán tratados sus datos personales,
            como los terceros con quienes compartimos su información personal y la forma en que
            podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en el
            portal de internet www.rasasc.com.
          </p>
        </div>
        {/*
        <div className="container relative md:mt-24 mt-16 lg:pt-24 pt-16">
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center z-1">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Tenemos al confianza de grandes aseguradoras y brokers
            </h3>
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-500 dark:text-white">
                <CountUp className="counter-value" start={1010} end={1548} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Siniestros atendidos</h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-500 dark:text-white">
                <CountUp className="counter-value" start={2} end={25} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Años de experienza</h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-500 dark:text-white">
                <CountUp className="counter-value" start={0} end={367800} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Dolares recuperados</h5>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              ¿Que dicen nuestros clientes?
            </h3>
          </div>

          <div className="flex justify-center relative mt-16">
            <div className="relative md:w-1/2 w-full">
              <div className="absolute -top-20 md:-start-24 -start-0">
                <i className="mdi mdi-format-quote-open text-9xl opacity-5"></i>
              </div>

              <div className="absolute bottom-28 md:-end-24 -end-0">
                <i className="mdi mdi-format-quote-close text-9xl opacity-5"></i>
              </div>

              <div className="tiny-single-item">
                <TinySlider settings={settings}>
                  {SERVICES.map((item, index) => {
                    return (
                      <div className="tiny-slide" key={index}>
                        <div className="text-center">
                          <p className="text-lg text-slate-500 italic"> {item.feedback} </p>

                          <div className="text-center mt-5">
                            <ul className="text-xl font-medium text-amber-400 list-none mb-2">
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                              <li className="inline">
                                <i className="mdi mdi-star"></i>
                              </li>
                            </ul>

                            <img
                              src={item.image}
                              className="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto"
                              alt=""
                            />
                            <h6 className="mt-2 font-semibold">{item.name}</h6>
                            <span className="text-slate-500 text-sm">{item.role}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              </div>
            </div>
          </div>
        </div>

        <ManagementTeam className="relative md:mt-24 mt-16" id={''} />
          */}
      </section>

      <Footer />
    </>
  );
}
