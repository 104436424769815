import React from 'react';
import { Mail } from 'feather-icons-react';
import { Link } from 'react-router-dom';

import logo_light from '@/assets/images/rasa/logo.png';
import american_ex from '@/assets/images/payments/american-ex.png';
import discover from '@/assets/images/payments/discover.png';
import master_card from '@/assets/images/payments/master-card.png';
import paypal from '@/assets/images/payments/paypal.png';
import visa from '@/assets/images/payments/visa.png';
import { ROUTES } from '@/constants/routes';

export default function Footer() {
  const footerLinks = [
    {
      liClass: 'mt-[10px]',
      route: 'https://s3.us-east-2.amazonaws.com/assets.rasa/docs/aviso_privacidad_2023.pdf',
      title: 'Aviso de privacidad',
    },
  ];
  const footerCompany = [
    {
      liClass: '',
      route: ROUTES.ABOUT_US,
      title: 'Acerca de nosotros',
    },
    {
      liClass: 'mt-[10px]',
      route: ROUTES.SERVICES,
      title: 'Servicios',
    },
  ];
  return (
    <div>
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-4 md:col-span-12">
                    <Link to="/#" className="text-[22px] focus:outline-none">
                      <img style={{ width: 90 }} src={logo_light} alt="" />
                    </Link>
                    <p className="mt-6 text-gray-300">Ajustadores especializados</p>
                  </div>

                  <div className="lg:col-span-2 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">RASA</h5>
                    <ul className="list-none footer-list mt-6">
                      {footerCompany.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            to={data.route}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i className="uil uil-angle-right-b"></i> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">Otros</h5>
                    <ul className="list-none footer-list mt-6">
                      {footerLinks.map((data, index) => (
                        <li key={index} className={data.liClass}>
                          <Link
                            target="_blank"
                            to={data.route}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i className="uil uil-angle-right-b"></i> {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container relative text-center">
            <div className="grid md:grid-cols-2 items-center">
              <div className="md:text-start text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} RASA. Design & Develop by{' '}
                  <Link to="" target="_blank" className="text-reset">
                    cloudgia.com
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
