import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Eye, Feather, Heart, Layout, Star } from 'feather-icons-react/build/IconComponents';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';

import ab03 from '@/assets/images/about/ab03.jpg';
import ab02 from '@/assets/images/about/ab02.jpg';
import ab01 from '@/assets/images/about/ab01.jpg';

import thyssenkrupp from '@/assets/images/mapia/thyssenkrupp.svg';
import quickpipes from '@/assets/images/mapia/quickpipes.svg';
import arcelormittal from '@/assets/images/mapia/arcelormittal.svg';
import cjf from '@/assets/images/mapia/cjf.svg';
import nkpm from '@/assets/images/mapia/nkpm.svg';
import schnellecke from '@/assets/images/mapia/schnellecke.svg';

import defaultUser from '@/assets/images/mapia/default-user.jpg';
import hector from '@/assets/images/rasa/hector.jpg';
import adriana from '@/assets/images/rasa/adriana.jpg';

import { ROUTES } from '@/constants/routes';

const settings = {
  container: '.tiny-single-item',
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  speed: 400,
  gutter: 16,
};

export default function PageAboutUs() {
  const [isOpen, setOpen] = useState(false);

  const services = [
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Thomas Israel',
      image: thyssenkrupp,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Carl Oliver',
      image: cjf,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Barbara McIntosh',
      image: arcelormittal,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Jill Webb',
      image: nkpm,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Dean Tolle',
      image: quickpipes,
    },
    {
      description:
        '" If the distribution of letters and \'words\' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "',
      name: 'Christa Smith',
      image: schnellecke,
    },
  ];
  const features = [
    { icon: Star, title: 'Honestidad' },
    { icon: Heart, title: 'Empatía' },
    { icon: Eye, title: 'Eficasia' },
    { icon: Layout, title: 'Eficiencia' },
    { icon: Feather, title: 'Estrategia' },
  ];
  const teamData = [
    {
      image: hector,
      name: 'Lic. Héctor Antonio Reynoso Macedo',
      title: 'Director General & Técnico Ajustador.',
      desc: function () {
        return (
          <>
            <li>
              Licenciado en comercio exterior con 10 años de experiencia en el sector asegurador y
              25 en el área Logística.
            </li>
            <li>Gestor de Riesgos en la cadena de transporte de mercancias.</li>
            <li>
              Administrador de los seguros institucionales en Volkswagen de México S.A. de C.V.
            </li>
            <li>
              Coordinador del Despacho de Ajustes en Volkswagen para la atención de siniestros.
            </li>
            <li>
              Especialista en la Gestión, evaluación y Administración de los riesgos asegurables y
              afianzables de Volkswagen de México.
            </li>
            <li>Experto en la gestión y atención de Siniestros Catastróficos (NAT-CAT).</li>
            <li>
              Conocimiento de los riesgos de programas como: Property, Responsabilidad Civil, Obra
              Civil, Transporte Mercancias y Misceláneos.
            </li>
          </>
        );
      },
      linkedin: '',
    },
    {
      image: adriana,
      name: 'Mtra. Adriana Caballero Campos',
      title: 'Director Administrativo & Ajustador',
      desc: function () {
        return (
          <>
            <li>Maestría en Comercio Internacional.</li>
            <li>Licenciada en Contaduría Pública.</li>
            <li>17 años de experiencia en el sector asegurador.</li>
            <li>
              Ajustador master para la cuenta de Volkswagen de México en el programa de Transporte
              Internacional de Mercancías.
            </li>
            <li>Experta en la gestión de salvamentos y destrucción de vehículos siniestrados.</li>
            <li>Coordinadora interina del despacho de ajuste INPLANT.</li>
            <li>
              Analista y gestora de programas de prevención contra daños en el Trasporte de
              mercancías.
            </li>
            <li>Experta en revaluación de activos para el programa de “Property”.</li>
            <li>Diseñadora y Planeadora del sistema de Administración de siniestros de “RASA”.</li>
            <li>
              Experta en la gestión de pérdidas de siniestros catastróficos (p.e. Hundimiento de
              buques y descarrilamiento de trenes etc.)
            </li>
          </>
        );
      },
      linkedin: '',
    },
  ];

  return (
    <>
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/company/aboutus.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Acerca de nosotros
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              En RASA nos dedicamos a proporcionar el mejor servicio de ajuste, asesoría y
              prevención de riesgos
            </p>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className=" tracking-[0.5px]  mb-0 inline-block space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to={ROUTES.HOME}>RASA</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="uil uil-angle-right-b"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Acerca de nosotros
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab03} className="shadow rounded-md" alt="" />
                    <img src={ab02} className="shadow rounded-md" alt="" />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src={ab01} className="shadow rounded-md" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div className="lg:ms-5">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  ¿Quienes somos?
                </h3>

                <p className="text-slate-500 max-w-xl">
                  Somos una empresa conformada por <b>expertos en diferentes campos</b> del sector
                  asegurador. Contamos con <b>amplia experiencia</b> en diferentes áreas del campo
                  productivo y <b>conocemos extensamente los procesos de distribución</b> de
                  mercancías. (Cadena de distribución Logística) Contamos con una{' '}
                  <b>visión innovadora enfocada en la mejora de los procesos</b> de{' '}
                  <b>“Ajuste de daños”</b> ya que{' '}
                  <b>procedemos de lado del cliente y conocemos las necesidades no satisfechas.</b>{' '}
                  Tenemos presente que, lo más importante para la <b>Aseguradora</b> es tener{' '}
                  <b>transparencia y claridad de la determinación de pérdidas</b> y del lado del{' '}
                  <b>cliente,</b> sabemos la{' '}
                  <b>importancia de responder con eficiencia y eficacia a la dictaminación</b> e
                  indemnización de los siniestros.
                </p>

                <div className="mt-6">
                  <Link
                    to={ROUTES.CONTACT_US}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md me-2 mt-2"
                  >
                    <i className="uil uil-envelope"></i> Contactanos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-sky-600">
              <div className="content mt-6">
                <Link
                  to="/page-services"
                  className="title h5 text-xl font-semibold hover:text-indigo-600"
                >
                  Nuestra Misión
                </Link>
                <p className="text-dark-500 mt-4">
                  Proveer a las Aseguradoras y a los Asegurados{' '}
                  <b>información y servicios de excelencia, profesionales, en tiempo y en forma</b>,
                  para dictaminar los siniestros encomendados a esta firma.
                </p>
              </div>
            </div>

            <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-sky-600">
              <div className="content mt-6">
                <Link
                  to="/page-services"
                  className="title h5 text-xl font-semibold hover:text-indigo-600"
                >
                  Nuestra Visión
                </Link>
                <p className="text-dark-500 mt-4">
                  Encaminar los esfuerzos de la compañía para que <b>crezca de forma sistemática</b>
                  ; darnos a conocer como <b>un socio estratégico confiable</b> de las Aseguradoras,
                  garantizar un <b>análisis eficiente</b> de los siniestros, siendo objetivos,
                  éticos y profesionales.
                </p>
              </div>
            </div>

            <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-sky-600">
              <div className="content mt-6">
                <Link
                  to="/page-services"
                  className="title h5 text-xl font-semibold hover:text-indigo-600"
                >
                  Nuestros Valores
                </Link>
                <p className="text-dark-500 mt-4">
                  Conducirnos con <b>honestidad, hablar con la verdad</b>, ser <b>empáticos</b> con
                  nuestros socios <b>estratégicos</b> y trabajar con enfoque en los resultados
                  siendo <b>eficaces y eficientes</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 md:pb-20 pb-20"
        id="team"
      >
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Nuestro equipo
            </h3>

            <p className="text-slate-500 max-w-xl mx-auto">
              Con mas de 15 años de experiencia nos comprometemos a brindarte el mejor servicio.
            </p>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
            {teamData.map((item, index) => {
              return (
                <div key={index} className="lg:col-span-6 md:col-span-4">
                  <div className="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900 text-center">
                    <img
                      src={item.image}
                      className="h-56 w-56 rounded-full shadow-md dark:shadow-gray-800 m-auto"
                      alt=""
                    />

                    <div className="content mt-4">
                      <Link to="#" className="text-lg font-medium hover:text-teal-600 block">
                        {item.name}
                      </Link>
                      <span className="text-slate-500 block">{item.title}</span>

                      {/*<p className="text-slate-500 mt-4">{item.desc() as any}</p>*/}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
