import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import Lightbox from 'react-18-image-lightbox';

import Navbar from '@/component/Navbar/navbar';
import Footer from '@/component/Footer/footer';

import image from '@/assets/images/portfolio/1.jpg';
import image2 from '@/assets/images/portfolio/3.jpg';
import image3 from '@/assets/images/portfolio/4.jpg';
import image4 from '@/assets/images/portfolio/5.jpg';
import image5 from '@/assets/images/portfolio/6.jpg';
import image6 from '@/assets/images/portfolio/7.jpg';
import image7 from '@/assets/images/portfolio/8.jpg';
import image8 from '@/assets/images/portfolio/9.jpg';
import image9 from '@/assets/images/portfolio/20.jpg';
import image10 from '@/assets/images/portfolio/21.jpg';
import image11 from '@/assets/images/portfolio/22.jpg';
import image12 from '@/assets/images/portfolio/23.jpg';

import { ROUTES } from '@/constants/routes';
import pro1 from '@/assets/images/rasa/property.jpg';
import pro2 from '@/assets/images/rasa/liability.jpg';
import pro3 from '@/assets/images/rasa/construction.jpg';
import pro4 from '@/assets/images/rasa/marine.jpg';
import pro5 from '@/assets/images/rasa/catastopher.jpg';
import pro6 from '@/assets/images/rasa/goverment.jpg';

const settings2 = {
  container: '.tiny-six-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    1025: {
      items: 6,
    },

    992: {
      items: 4,
    },

    767: {
      items: 3,
    },

    320: {
      items: 1,
    },
  },
};
export default function PageServices() {
  const [isOpen, setisOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const services = [
    {
      image: image,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image2,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image3,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image4,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image5,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image6,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image7,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image8,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image9,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image10,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image11,
      name: 'Iphone mockup',
      role: 'Branding',
    },
    {
      image: image12,
      name: 'Iphone mockup',
      role: 'Branding',
    },
  ];
  const images = [
    image,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  const handleMovePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
  };

  const handleMoveNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setisOpen(true);
  };
  const currentImage = images[currentImageIndex];

  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/services.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mt-2 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Nuestros Servicios
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className=" tracking-[0.5px]  mb-0 inline-block space-x-1">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to={ROUTES.HOME}>RASA</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="uil uil-angle-right-b"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Servicios
            </li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-2">
            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                    <img
                      src={pro1}
                      style={{ width: '100%' }}
                      className="rounded-t-md shadow"
                      alt=""
                    />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      Daños a propiedad
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Property)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    <b>Experiencia en el manejo</b> de reclamos por{' '}
                    <b>daños, interrupción de negocios y seguro contingente.</b> Consideramos de
                    manera proactiva la participación de especialistas en{' '}
                    <b>
                      investigación de incendios, consideraciones de cobertura, mitigación de
                      pérdidas, cuantificación y subrogación de pérdidas.
                    </b>{' '}
                    También trabajamos junto a contadores forenses en pérdidas de interrupción de
                    negocios más complejas.
                  </p>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                    <img
                      src={pro2}
                      style={{ width: '100%' }}
                      className="rounded-t-md shadow"
                      alt=""
                    />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-emerald-600 transition-all duration-500 ease-in-out"
                    >
                      Seguro de responsabilidad
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Liability)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    Nuestros servicios van desde{' '}
                    <b>
                      proporcionar una investigación de las circunstancias y asegurar declaraciones
                      de testigos
                    </b>{' '}
                    hasta ajustes completos, incluidas{' '}
                    <b>
                      evaluaciones cuánticas, negociaciones de acuerdos y asistencia técnica y legal
                    </b>{' '}
                    dedicada tanto para aseguradoras locales como para mercados internacionales de
                    reaseguros.
                  </p>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-red-600/5 dark:bg-red-600/30">
                    <img src={pro3} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-red-600 transition-all duration-500 ease-in-out"
                    >
                      Ingeniería y construcción
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Construction & Engineering)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    <b>Manejo de pérdidas</b> que involucran{' '}
                    <b>proyectos de construcción e ingeniería civil.</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-sky-600/5 dark:bg-sky-600/30">
                    <img src={pro4} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-sky-600 transition-all duration-500 ease-in-out"
                    >
                      Seguro de transportes
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Marine & Inland Merine)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    Gestión de <b>reclamos</b> que incluyen:{' '}
                    <b>
                      casco y maquinaria, carga general, responsabilidad de los transportistas,
                      responsabilidad del almacenista y pérdidas de producción.
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-amber-600/5 dark:bg-amber-600/30">
                    <img src={pro5} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-amber-600 transition-all duration-500 ease-in-out"
                    >
                      Respuesta a catástrofes
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Catastrophe Response)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    Gestión de <b>siniestros catastróficos.</b> Establecemos un plan de catástrofe
                    comprobado, <b>estamos listos</b> para responder a cualquier{' '}
                    <b>situación de pérdida por catástrofes.</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="picture-item p-4 rounded-md">
              <div className="">
                <div className="relative">
                  <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                    <img src={pro6} className="rounded-t-md shadow" alt="" />
                  </div>
                </div>

                <div className="pt-4 px-3">
                  <h5 className="mb-1 font-semibold text-lg">
                    <Link
                      to="#"
                      className="hover:text-indigo-600 transition-all duration-500 ease-in-out"
                    >
                      Cuentas gubernamentales
                    </Link>
                  </h5>
                  <span className="text-slate-500">(Goverment Accounts)</span>
                  <p className="text-slate-500 mt-4 text-justify">
                    Conocemos la administración de reclamos de <b>cuentas gubernamentales,</b>{' '}
                    ofrecen experiencia en la mayoría de los aspectos del{' '}
                    <b>manejo de grandes volúmenes de reclamos, evaluación de daños</b> a través de
                    personal técnico especializado y{' '}
                    <b>control de reclamos sobre las capas de retención de pérdidas,</b> según las
                    instrucciones de los reaseguradores.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h6 className="text-sky-600 md:text-3xl font-bold mb-2">RASA es tu mejor opción</h6>
            <h3 className="mb-4 md:leading-normal text-2xl leading-normal font-semibold">
              Para asesoría, apoyo y dictaminación de perdidas
            </h3>
            <div className="mt-6">
              <Link
                to={ROUTES.CONTACT_US}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-teal-700 border-teal-600 hover:border-teal-700 text-white rounded-md mt-4"
              >
                <i className="uil uil-phone"></i> Contactanos
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
