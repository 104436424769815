import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'feather-icons-react';

import Navbar from '@/component/Navbar/navbar';
import {
  Facebook,
  Github,
  Gitlab,
  Instagram,
  Linkedin,
  Mail,
  MapPin,
  Phone,
  Twitter,
  Youtube,
} from 'feather-icons-react/build/IconComponents';

export default function ContactDetail() {
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="md:h-screen flex py-36 w-full items-center bg-[url('../../assets/images/cta.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-black/70"></div>
        <div className="container relative">
          <div className="lg:flex justify-center mt-12">
            <div className="lg:w-11/12 bg-white dark:bg-slate-900 rounded-md shadow-lg dark:shadow-gray-800 overflow-hidden">
              <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                <div className="lg:col-span-7 md:col-span-6">
                  <div className="w-full leading-[0] border-0">
                    <iframe
                      title="google"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.8064357259277!2d-98.205904924362!3d18.9841540548487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfbf40e977c7ad%3A0x96ae6826b1b3af09!2sVasco%20de%20Quiroga%208A%2C%20Fundadores%2C%2072583%20Puebla%2C%20Pue.!5e0!3m2!1ses-419!2smx!4v1692761958203!5m2!1ses-419!2smxhttps://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0838372192743!2d-98.27477372065431!3d19.060051200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc666ffffffff%3A0xedb1f467341ce02b!2sClub%20Deportivo%20Volkswagen!5e0!3m2!1ses-419!2smx!4v1699314848411!5m2!1ses-419!2smx"
                      style={{ border: '0' }}
                      className="w-full lg:h-[540px] md:h-[600px] h-[200px]"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>

                <div className="lg:col-span-5 md:col-span-6">
                  <div className="p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-medium">
                      Hablemos sobre tu proyecto
                    </h3>
                    <div className="flex items-center mt-6">
                      <Mail className="w-6 h-6 me-4"></Mail>
                      <div className="">
                        <h5 className="title font-bold mb-0">Email</h5>
                        <Link
                          to="/hector.reynoso@rasasc.com"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-teal-600 hover:text-teal-600 after:bg-teal-600 duration-500"
                        >
                          hector.reynoso@rasasc.com
                        </Link>
                        <br />
                        <Link
                          to="/adriana.caballero@rasasc.com"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-teal-600 hover:text-teal-600 after:bg-teal-600 duration-500"
                        >
                          adriana.caballero@rasasc.com
                        </Link>
                      </div>
                    </div>

                    <div className="flex items-center mt-6">
                      <Phone className="w-6 h-6 me-4"></Phone>
                      <div className="">
                        <h5 className="title font-bold mb-0">Phone</h5>
                        <Link
                          to="/tel:+52 222 328 6404"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-teal-600 hover:text-teal-600 after:bg-teal-600 duration-500"
                        >
                          +52 222 328 6404
                        </Link>
                        <br />
                        <Link
                          to="/tel:+52 222 328 6404"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-teal-600 hover:text-teal-600 after:bg-teal-600 duration-500"
                        >
                          +52 222 183 7370
                        </Link>
                      </div>
                    </div>

                    <div className="flex items-center mt-6">
                      <MapPin className="w-6 h-6 me-4"></MapPin>
                      <div className="">
                        <h5 className="title font-bold mb-0">Location</h5>
                        <Link
                          to="https://www.google.com.mx/maps/search/CARRETERA+LATERAL+RECTA+CHOLULA-PUEBLA+4000+SANTIAGO+MOMOXPAN,+SAN+ANDRES+CHOLULA+PUEBLA/@19.0600512,-98.2747737,17z?entry=ttu"
                          data-type="iframe"
                          className="video-play-icon relative inline-block font-semibold tracking-wide align-middle ease-in-out text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-teal-600 hover:text-teal-600 after:bg-teal-600 transition duration-500 lightbox"
                        >
                          Ver en Google maps
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center text-center p-6 border-t border-gray-100 dark:border-gray-800">
                    <p className="mb-0 text-slate-400">
                      © {new Date().getFullYear()} RASA. Design & Develop by{' '}
                      <Link to="" target="_blank" className="text-reset">
                        cloudgia.com
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="fixed bottom-3 end-3">
        <Link
          to="#"
          className="back-button h-9 w-9 inline-flex items-center justify-center tracking-wide border align-middle duration-500 text-base text-center bg-teal-600 hover:bg-indigo-700 border-teal-600 hover:border-indigo-700 text-white rounded-full"
        >
          <ArrowLeft className="h-4 w-4"></ArrowLeft>
        </Link>
      </div>
    </>
  );
}
